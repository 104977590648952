import React, { useContext, useState } from 'react';
import Header from '../../Components/Header/Header';
import * as styles from './Contatti.module.css';
import ButtonBox from '../../Components/ButtonBox/ButtonBox';
// import VisitContent from '../../Components/VisitContent/VisitContent'
import Footer from '../../Components/Footer/Footer';
// import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
// import InputField from '../../Components/InputField/InputField';
// import TextareaField from '../../Components/TextareaField/TextareaField';
import { graphql, Link } from 'gatsby'
import { SEO } from '../../Components/Seo/seo';
import { languageContext } from '../../Components/Header/Header';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';
import classNames from 'classnames';
import addToMailchimp from 'gatsby-plugin-mailchimp';


const Contatti = ({ data }) => {
  const llanguage = useContext(languageContext)
  const { language } = llanguage
  // const isMobile = typeof window !== 'undefined' && window.innerWidth < 767;

  const [details, setDetails] = useState({
    name: '',
    email: '',
    message: ''
  })
  // const [email, setEmail] = useState('');
  // const [yourName, setyourName] = useState('');
  // const [message, setMessage] = useState('');
  const [result, setResult] = useState('');
  // const [inProgress, setInprogress] = useState(false);
  
  const handleSubmit = async (e) => {
    // setInprogress(true)
    e.preventDefault();

    if (!details?.email) {
      setResult('Please enter your email.');
      setTimeout(() => {
        setResult(null);  
      },3000)
      return;
    }

    const { 
      // result, 
      msg } = await addToMailchimp(details.email, {
      NAME: details.name,
      MESSAGE: details.message,

    });
    if(msg === 'Thank you for subscribing!'){
      setDetails({
        name: '',
        email: '',
        message: ''
      })
      // setInprogress(false)
      setResult(msg);
    }
    setTimeout(() => {
      setResult(null);  
    },3000)

  };

  const localizations = data?.allStrapiContact?.nodes[0]?.localizations
  const { 
    // ContactAddress,
    //  ContactTitle,
      FormButton,
       PageTitle, ServiceDescription, ServiceTitle,
      // TimingTitle, 
      // TimingDescription,
       ServiceFormPlaceHolder } = language === 'it' ? localizations?.data[0]?.attributes : data.allStrapiContact.nodes[0]



  // const MyMapComponent = withScriptjs(withGoogleMap((props) =>
  //   <GoogleMap
  //     defaultZoom={25}
  //     defaultCenter={{ lat: 46.033657435773215, lng: 13.505279019707366 }}
  //   >
  //     {props.isMarkerShown && <Marker position={{ lat: 46.033657435773215, lng: 13.505279019707366 }} />}
  //   </GoogleMap>
  // ))

  return (
    <>
      <div className='pageWrapper'>
        <div>
          <Header />
          <div className={styles.contattiBox}>
            <AnimationOnScroll animateIn='animate__animated animate__fadeInUp'>
              <div className={classNames(styles.headingName, 'animate__animated animate__fadeInUp')}>{PageTitle}</div>
            </AnimationOnScroll>
            <div className={styles.googleMap}>
              {/* <MyMapComponent
                isMarkerShown
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: isMobile ? "379px" : "532px" }} />}
                mapElement={<div style={{ height: `100%` }} />}
              /> */}
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d44317.992939027106!2d13.4702584!3d46.0336515!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477a53484cb54c37%3A0x748acb434e5b0962!2sTenuta%20La%20Ponca%20Srl%20-%20Societa&#39;%20Agricola!5e0!3m2!1sen!2sin!4v1680256809614!5m2!1sen!2sin" width="100%" height="532"></iframe>

              {/* <VisitContent
                    contatti={true}
                    /> */}
            </div>
            {/* <div className={styles.addressContact}>
              <div className={styles.addressBox}> */}
            {/* <div className={styles.addressType}>
                  <AnimationOnScroll animateIn='animate__animated animate__fadeInUp'>
                    <h4 className='animate__animated animate__fadeInUp'>{ContactTitle}</h4>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn='animate__animated animate__fadeInUp'>
                    <p className='animate__animated animate__fadeInUp'>
                      {ContactAddress}
                    </p>
                  </AnimationOnScroll>
                </div> */}
            {/* <div className={styles.contactTiming}>
                  <AnimationOnScroll animateIn='animate__animated animate__fadeInUp'>
                    <h4 className='animate__animated animate__fadeInUp'>{TimingTitle}</h4>
                  </AnimationOnScroll>
                  {TimingDescription.map((item) => (
                    <div className={styles.timingBox}>
                      <AnimationOnScroll animateIn='animate__animated animate__fadeInUp'>
                        <p className='animate__animated animate__fadeInUp'>{item.Description}</p>
                      </AnimationOnScroll>
                    </div>
                  ))}
                </div> */}
            {/* </div
            </div>v> */}
            <div className={styles.contactForm}>
              {/* <AnimationOnScroll animateIn='animate__animated animate__fadeInUp'>
                <h2 className='animate__animated animate__fadeInUp'>{ServiceTitle}</h2>
              </AnimationOnScroll> */}

              {/* <form onSubmit={handleSubmit}>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                />
                <button type="submit">Subscribe</button>
                <div>{result}</div>
              </form> */}
              {/* <form className={styles.formBox} onSubmit={handleSubmit}>
                <div className={styles.rowBox}>
                  <AnimationOnScroll animateIn='animate__animated animate__fadeInUp'>
                    <input
                      name="yourName"
                      value={details.name}
                      onChange={e => setDetails({...details, name: e.target.value})}
                      type="text"
                      placeholder={ServiceFormPlaceHolder.Name}
                      className={classNames(styles.inputBox, "animate__animated animate__fadeInUp")}
                    />
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn='animate__animated animate__fadeInUp'>
                    <input
                      name="email"
                      value={details.email}
                      type="email"
                      onChange={e => setDetails({...details, email: e.target.value})}
                      placeholder={ServiceFormPlaceHolder.Email}
                      className={classNames(styles.inputBox, "animate__animated animate__fadeInUp")}
                    />
                  </AnimationOnScroll>
                </div>
                <div className={styles.inputFull}>
                  <AnimationOnScroll animateIn='animate__animated animate__fadeInUp'>
                    <textarea
                      name="message"
                      value={details.message}
                      onChange={e => setDetails({...details, message: e.target.value})}
                      placeholder={ServiceFormPlaceHolder.Message}
                      className={classNames(styles.inputBox, "animate__animated animate__fadeInUp")}
                    ></textarea>
                  </AnimationOnScroll>
                </div>

                <AnimationOnScroll animateIn='animate__animated animate__fadeInUp'>
                  <h5 className={classNames(styles.subText, 'animate__animated animate__fadeInUp')}>{ServiceDescription}</h5>
                </AnimationOnScroll>
                <div className={styles.contactButton}>
                  <AnimationOnScroll animateIn='animate__animated animate__fadeInUp'>
                    <Link className='animate__animated animate__fadeInUp' to={''}>
                      <ButtonBox
                        buttonName={FormButton.label}
                        handleSubmit={handleSubmit}
                      />
                    </Link>
                  </AnimationOnScroll>
                  <div className={styles.tosterResult}>{result}</div>
                </div>
              </form> */}
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contatti;
export const query = graphql`
  query MyQuery {
    allStrapiContact {
      nodes {
        TimingTitle
        TimingDescription {
          Description
        }
        PageTitle
        ServiceDescription
        ServiceTitle
        MetaKeyword
        MetaDescription
        ContactAddress
        ContactTitle
        FormButton {
          href
          label
        }
        ServiceFormPlaceHolder {
          Email
          Message
          Name
        }
        localizations {
            data {
              attributes {
                ContactAddress
                ContactTitle
                PageTitle
                ServiceDescription
                locale
                MetaKeyword
                MetaDescription
                ServiceFormPlaceHolder {
                  Email
                  Message
                  Name
                }
                ServiceTitle
                FormButton {
                  href
                  label
                }
                TimingDescription {
                  Description
                }
                TimingTitle
              }
            }
        }
      }
    }
  }
  
`;

export const Head = ({ data }) => {
  const localizations = data?.allStrapiContact?.nodes[0]?.localizations
  const { MetaKeyword , MetaDescription } = localizations?.data[0]?.attributes 
  return <SEO title={MetaKeyword} description={MetaDescription} />
}
