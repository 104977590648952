// extracted by mini-css-extract-plugin
export var addressBox = "Contatti-module--addressBox--053d0";
export var addressContact = "Contatti-module--addressContact--6c133";
export var addressType = "Contatti-module--addressType--8963b";
export var contactButton = "Contatti-module--contactButton--7a7b3";
export var contactForm = "Contatti-module--contactForm--707b8";
export var contactTiming = "Contatti-module--contactTiming--8758b";
export var contattiBox = "Contatti-module--contattiBox--894f7";
export var formBox = "Contatti-module--formBox--6e1b8";
export var googleMap = "Contatti-module--googleMap--db064";
export var headingName = "Contatti-module--headingName--7597f";
export var inputBox = "Contatti-module--inputBox--695eb";
export var inputFull = "Contatti-module--inputFull--23406";
export var rowBox = "Contatti-module--rowBox--7e833";
export var subText = "Contatti-module--subText--f7300";
export var tosterResult = "Contatti-module--tosterResult--d7568";